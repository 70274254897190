import React from 'react'
import PropTypes from "prop-types";

const Heading = ({ children, type, fontWight, ...property }) => {
    const isSubHeading = type?.includes('subheading')
    const HeadingVariants = {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
    };
    const subHeadingStyle = {
        subheading1: `text-2xl md:text-5xl ${fontWight ? fontWight : "font-bold"}`,
        subheading2: `text-2xl md:text-4xl  ${fontWight ? fontWight : "font-bold"}`,
        subheading3:`text-base md:text-3xl ${fontWight ? fontWight : "font-bold"} `,
        subheading4:`text-xl md:text-2xl ${fontWight ? fontWight : "font-bold"}`,
    };

    let Type = HeadingVariants[type] ? HeadingVariants[type] : 'h1'
    Type = isSubHeading ? "p" : Type
    property.className = isSubHeading ? `${subHeadingStyle[type] || ""} ${property.className || ""}` : `font-poppins font-semibold   ${property.className || ""}`
    if (!isSubHeading && !property.className) delete property.className
    return (
        <Type {...property}>
            {children}
        </Type>
    );
};

Heading.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    fontWight: PropTypes.string,
    className: PropTypes.string,
};

export default Heading;