import React from "react";
import PropTypes from "prop-types";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import Image from "next/image";
import { useRouter } from "next/router";
import Button from "@atoms/Button";

function PackageList({
  data,
  className,
  headingSize = "text-2xl md:text-3xl",
  textSize,
  nameSize,
  shadow = "shadow-book",
  topPart = "py-8",
  initiatedBy,
  padding = "px-5 md:px-10 pt-5",
  refId,
  descriptionClass,
  TextType,
  onClickChatOrSession,
  ...props
}) {
  const router = useRouter();
  return (
    <div className={`flex flex-col ${className}`}>
      {/* <Heading type='h3' className='mb-4 text-center flex-none'>{data.type}</Heading> */}
      <div
        className={`relative text-center rounded-lg flex-1 ${padding} ${shadow} `}
      >
        <span className="absolute top-2 right-0 bg-lightSpray text-xs text-white px-5 py-1">
            {data.type}
          </span>
        <div className={topPart}>
          <Text
            type={`${
              nameSize === "small" ? "bodyStyleSmall" : "bodyStyleLarge"
            }`}
            className={`uppercase mb-4`}
          >
            {data.name}
          </Text>
          <Heading type="h1" className={`font-semibold mb-2 ${headingSize}`}>
            {data.discountMessage}
          </Heading>
          {data.couponDetails && <Text type="bodyStyleDefault" className={`font-semibold `}>
            {data.couponDetails}
          </Text>}
          <Text
            type={TextType}
            className={`text-textGrey  ${descriptionClass} mx-auto ${textSize}`}
          >
            {data.description}
          </Text>
          {["Session","Chat"].includes(data.type) ? (
            <Button
              type="mediumContained"
              variant="primary"
              className="uppercase mx-auto mt-4 "
              onClick={onClickChatOrSession}
            >
              Book {data.type}
            </Button>
          ) : (
            <Button
              type="mediumContained"
              variant="primary"
              className="uppercase mx-auto mt-4"
              onClick={() => {
                router.push({
                  pathname: `${process.env.NEXT_PUBLIC_MAIN_URL}/ref/${refId}`,
                  query: {
                    packageId: data.id,
                    type: "Package",
                  },
                });
              }}
            >
              Buy Now
            </Button>
          )}
        </div>
        <div className="border-t py-3 ">
          <div className="w-max mx-auto ">
            <div className="flex">
              <Image src="/images/tick.svg" width={14} height={14} />
              <Text className="ml-1">{data.noOfSessions} {["Chat"].includes(data.type)? 'Chat':'Video'} Session</Text>
            </div>
            <div className="flex">
              <Image src="/images/tick.svg" width={14} height={14} />
              <Text className="ml-1">
                {["Session","Chat"].includes(data.type)
                  ? "Book Instantly"
                  : `${data.validityOfPackage} Months Validity`}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageList;


PackageList.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  headingSize: PropTypes.string,
  textSize: PropTypes.string,
  nameSize: PropTypes.string,
  shadow: PropTypes.string,
  topPart: PropTypes.string,
  initiatedBy: PropTypes.string,
  padding: PropTypes.string,
  refId: PropTypes.string,
  descriptionClass: PropTypes.string,
  TextType: PropTypes.string,
  onClickChatOrSession: PropTypes.func,
};