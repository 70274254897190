import Image from "next/image";
import PropTypes from "prop-types";

const Button = ({
    children,
    onClick,
    isArrowShow = false,
    type = "largeContained",
    variant = "primary",
    width = "w-max",
    rounded = "rounded-lg",
    padding = "px-6",
    btnClass = "",
    ...property
}) => {
    const variantStyle = {
        primary:
            "bg-primary-100 text-white border border-primary-100 hover:bg-transparent hover:text-primary-100",
        secondary: "bg-white text-primary-100 border",
        tirtiary:
            "bg-[#F47A82] text-white border border-[#F47A82] hover:bg-transparent hover:text-[#F47A82]",
        sunshinePrimary:
            "px-6 py-3 text-sm font-nunito text-secondary-700 leading-5 bg-white whitespace-nowrap !font-semibold",
        sunshineSecondary:
            "px-12 md:px-16 py-3 md:py-4 text-sm font-nunito text-secondary-700 leading-5 bg-secondary-700 !text-white whitespace-nowrap !font-semibold",
        sunshinePrimaryGreen:
            "px-6 py-3 text-sm font-nunito text-white leading-5 bg-green-200",
        sunshinePrimaryGreenText:
            "px-6 py-3 md:px-4 md:py-2 lg:px-6 lg:py-3 text-sm md:text-[8px] lg:text-base font-nunito text-lightGreen leading-5 bg-white",
        sunshineLocationPrimary:
            "py-3 text-sm font-nunito text-black  !font-semibold",
        neutral: "bg-neutral-60 text-white",
        blue: "bg-jacksons-purple text-white",
        bgWhiteTexLapisBlue: "text-lapis-blue bg-white",
        danger: "bg-white text-red-350 border border-red-350 rounded-lg",
        bgJacksonsPurpleTextWhite: "bg-jacksons-purple text-white border",
        bgWhiteTextJacksonsPurple: "text-jacksons-purple bg-white border",
        bgWhiteTextPrimaryText:
            "text-primary-100 bg-white border hover:bg-transparent hover:text-white",
        primaryOutline:
            "bg-transparent border border-primary-100 text-primary-100",
        secondaryOutline:
            "bg-transparent border border-primary-100 border-white text-white hover:bg-white hover:text-lightSpray hover:border-lightSpray",
        secondaryNeutral:
            "bg-white text-primary-100 border hover:bg-lightSpray hover:text-white hover:border-lightSpray",
    };

    const buttonStyle = {
        largeContained: "font-bold py-3",
        mediumContained: "text-sm font-bold py-2.5",
        smallContained: "text-xs font-bold py-2.5",
    };

    return (
        <button
            type={type}
            className={`flex justify-center transition duration-100 ease-in ${
                variantStyle[variant]
            } ${buttonStyle[type]} ${rounded} ${
                property.className
            } ${width} ${padding} ${
                property.disabled &&
                "cursor-not-allowed opacity-50 hover:opacity-50"
            } ${btnClass}`}
            onClick={(e) => onClick && onClick(e)}
            {...(property.disabled && { disabled: property.disabled })}
        >
            {children}
            {isArrowShow && (
                <div
                    className={`w-4 h-4 rounded-full ${
                        variant === "secondary" ? "bg-[#64C4D3]" : "bg-white"
                    } flex justify-center items-center ml-3`}
                >
                    <Image
                        src={`/assets/images/${
                            variant === "secondary"
                                ? "right.svg"
                                : "right-light.svg"
                        }`}
                        alt="Right Arrow"
                        objectFit="cover"
                        width={7}
                        height={6}
                    />
                </div>
            )}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    isArrowShow: PropTypes.bool,
    type: PropTypes.string,
    variant: PropTypes.string,
    width: PropTypes.string,
    rounded: PropTypes.string,
    padding: PropTypes.string,
    btnClass: PropTypes.string,
};

export default Button;