import {
    GET_GODIGIT_PACKAGE_URL,
    GET_REF_USER_TOKEN
  } from './url.service';
  import * as HttpService from "./http.service";
  
  
  export const getGoDigitPackages = () => {
    return HttpService.getWithAuth(GET_GODIGIT_PACKAGE_URL())
  };

  export const getRefUserToken = (id) => {
    return HttpService.getWithAuth(GET_REF_USER_TOKEN(id))
  }