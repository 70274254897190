import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Heading from "@atoms/Heading";
import PackageList from "@molecules/PackageList";
import { getGoDigitPackages } from "@services/package.service";
import Header from "./Header";
import Head from "next/head";

function GoDigit() {
  const router = useRouter();
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    getGoDigitPackages().then((response) => {
      if (response.status) {
        setPackages(response.entity);
      }
    });
  }, []);

  const terms = [
    "Only one offer can be redeemed at a time and cannot be combined with other offers",
    "Cost for availing the service shall be borne by the user directly",
    "The offer will be valid for 1 year from the policy start date",
    "Digit is not responsible in any manner for nature or quality of products/services provided by Lissun",
    "You can refer to Lissun’s Terms and Conditions before availing any service",
  ];

  const onClickChatOrSession = () => {
    router.push({
      pathname: `${process.env.NEXT_PUBLIC_MAIN_URL}/ref/${router?.query?.refId}`,
      query: {
        packageId: "",
        type: "Session",
      },
    });
  }

  return (
    <>
      <Header
        logo="logo-lissun.svg"
        className="text-center md:text-left"
      ></Header>
         <Head>
         <title>{'GODIGIT'}</title>
            </Head>

      <div className="pt-6 md:pt-12 pb-6 px-6 md:px-0">
        <Heading
          type="h2"
          className="text-2xl md:text-4xl mb-6 md:mb-12 text-center"
        >
          Our Offerings
        </Heading>
        {packages && packages.length ? (
          <div className="flex flex-wrap justify-center gap-6 md:gap-10 max-w-4xl mx-auto mb-10">
            {packages.map((list) => {
              return (
                <PackageList
                  className="flex-1"
                  descriptionClass="max-w-[220px]"
                  key={list.id}
                  data={list}
                  refId={router?.query?.refId}
                  TextType={"bodyStyleExtraLarge"}
                  onClickChatOrSession={onClickChatOrSession}
                />
              );
            })}
          </div>
        ) : (
          <div className="text-xl text-center my-8">
            There are no packages available now.
          </div>
        )}

        <div className="mt-14 max-w-2xl mx-auto">
          <Heading
            type="h2"
            className="text-2xl md:text-4xl mb-6 md:mb-10 text-center"
          >
            Terms and conditions{" "}
          </Heading>
          <ul className="list-none flex flex-col gap-3">
            {terms.map((term, index) => {
              return (
                <li
                  key={index}
                  className="flex items-center gap-3 text-sm md:text-base"
                >
                  <div className="flex items-center flex-none">
                    <Image
                      src={`/images/list-icon-primary.svg`}
                      width={24}
                      height={24}
                    />
                  </div>
                  {term}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default GoDigit;
